<template>
  <div>
    <course-view-add-student
      @refreshStudents="$emit('refreshStudents')"
      :course-data="courseData"
      :is-add-new-student-active.sync="isAddNewStudentActive"
    />

    <b-card title="Students" no-body class="m-0">
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>

            <b-form-checkbox switch id="session-visible" v-model="isExpandSession" name="session-visible" class="ml-3">
              Show Session
            </b-form-checkbox>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button
                variant="outline-primary"
                v-if="courseData.metadata && courseData.metadata.primarySession"
                class="mr-1"
                disabled
              >
                <span class="text-nowrap">Add Student</span>
              </b-button>

              <b-button variant="primary" @click="doRosterExport">
                <span class="text-nowrap">Export Roster</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refStudentsListTable"
        class="position-relative"
        :items="students"
        responsive
        :fields="tableColumns"
        :filter="searchQuery"
        :filterByFormatted="true"
        primary-key="id"
        show-empty
        empty-text="No students enrolled in course"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
      >
        <!-- Column: Course -->
        <template #cell(studentId)="data">
          {{ data.item.student.studentId }}
        </template>

        <template #cell(session)="data">
          <div>
          <b-link
            :to="{ name: 'apps-roster-session-view', params: { id: data.item.session.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.session.name }}
          </b-link>
          <small class="text-muted">{{ title(data.item.session.status) }}</small>
        </div>
        </template>


        <!-- Column: Course -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.student.fullName }}
          </b-link>

          <small class="text-muted">#{{ data.item.student.studentId }}</small>
        </template>

        <!-- Column: Course -->
        <template #cell(email)="data">
          {{ data.item.student.email }}
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(funding)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileProgramVariant(data.item.attributes.fundingSource)}`"
            class="text-capitalize"
          >
            {{ title(data.item.attributes.fundingSource) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Payment Status -->
        <template #cell(payment)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfilePaymentStatusVariant(data.item.attributes.paymentStatus)}`"
            class="text-capitalize"
          >
            {{ title(data.item.attributes.paymentStatus) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Uploaded Resume -->
        <template #cell(resume)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedResume)}`"
            class="text-capitalize"
          >
            {{ title(data.item.attributes.uploadedResume) }}
          </b-badge>
        </template>

        <!-- Column: Attributes - Uploaded COE -->
        <template #cell(coe)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedCoe)}`"
            class="text-capitalize"
          >
            {{ title(data.item.attributes.uploadedCoe) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Voucher -->
        <template #cell(examVoucher)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(courseField(data.item, 'examVoucher'))}`"
            class="text-capitalize"
          >
            {{ title(courseField(data.item, 'examVoucher')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Taken? -->
        <template #cell(examTaken)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(courseField(data.item, 'examTaken'))}`"
            class="text-capitalize"
          >
            {{ title(courseField(data.item, 'examTaken')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Passed? -->
        <template #cell(examPassed)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(courseField(data.item, 'examPassed'))}`"
            class="text-capitalize"
          >
            {{ title(courseField(data.item, 'examPassed')) }}
          </b-badge>
        </template>

        <!-- Column: Course Exam Passed? -->
        <template #cell(labs)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(courseField(data.item, 'labCompleted'))}`"
            class="text-capitalize"
          >
            {{ title(courseField(data.item, 'labCompleted')) }}
          </b-badge>
        </template>

        <!-- Column: Transfer of Credit -->
        <template #cell(toc)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileYNVariant(courseField(data.item, 'toc').enabled)}`"
            class="text-capitalize"
          >
            {{ title(courseField(data.item, 'toc').enabled ? 'Yes' : 'No') }}
          </b-badge>
        </template>


        <!-- Column: Course Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveProfileStatusVariant(courseField(data.item, 'status'))}`"
            class="text-capitalize"
          >
            {{ title(courseField(data.item, 'status')) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              :to="{ name: 'apps-roster-profile-edit', params: { id: data.item.id }, query: { course: courseData.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="removeStudentsFromCourse([data.item.id])">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="students.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardBody,
  BCardTitle,
  BCardText,
  BFormCheckbox,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted, computed, watch } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useCourseStudentsList from './useCourseStudentsList';
import rosterStoreModule from '../rosterStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

import CourseViewAddStudent from './CourseViewAddStudent';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import useSessionStudentsList from '@/views/apps/roster/sessions-view/useSessionStudentsList';
import fileDownload from 'js-file-download';

export default {
  components: {
    CourseViewAddStudent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    BCardTitle,
    BCardActions,
    BCardText,
    BFormCheckbox,

    vSelect,
  },
  props: {
    courseData: { type: Object, required: true },
  },
  methods: {
    courseField(profile, field) {
      const { id: courseId } = this.courseData;

      if (!profile.courses || !profile.courses[courseId]) {
        return 'na';
      }

      return profile.courses[courseId][field];
    },
    async removeStudentsFromCourse(studentIds) {
      if (!studentIds || studentIds.length === 0) {
        console.log(`no students selected or invalid id lsit`);
        return;
      }

      const response = await store
        .dispatch('app-roster/removeStudentsFromCourse', {
          courseId: this.courseData.id,
          profileIds: studentIds,
        })
        .catch((error) => {
          console.error(`failed to remove students to class`, error);
        });

      if (!response) return false;
      this.$emit('refreshStudents');

      console.log(`added removed`, response.data);
    },
    assignNewStudent() {},
    doClassEdit(clazz) {
      console.log(`editing`, clazz);
    },
    doRosterExport() {
      console.log(`roster export`);
      this.$store
        .dispatch('app-roster/fetchCourseRoster', {
          id: this.courseData.id,
        })
        .then((response) => {
          const { data } = response;
          fileDownload(data, `${this.courseData.name} Roster.xlsx`);
        })
        .catch((error) => {
          console.error(`failed to add students to class`, error);
        });
    },
  },
  data: () => {
    return {
      isAddNewStudentActive: false,
      editClassData: {},
    };
  },
  computed: {
    students() {
      if (!this.courseData || !this.courseData.profiles) return [];
      return this.courseData.profiles;
    },
    dataMeta() {
      const localItemsCount = this.refStudentsListTable ? this.refStudentsListTable.localItems.length : 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.students.length,
      };
    },
  },
  setup(props) {
    const COURSE_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME))
      store.registerModule(COURSE_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
    });

    const { perPage, currentPage, perPageOptions, sortBy, isSortDirDesc, refStudentsListTable } = useCourseStudentsList(
      props.courseData
    );

    const {
      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,
    } = useSessionStudentsList({});

    const searchQuery = ref('');

    const isExpandSession = ref(false)

    const defaultColumns = [
      { key: 'name' },
      { key: 'email', sortable: true },
      { key: 'funding', sortable: true },
      { key: 'toc', sortable: true },
      { key: 'examVoucher', sortable: true },
      { key: 'examTaken', sortable: true },
      { key: 'examPassed', sortable: true },
      { key: 'labs', sortable: true },
      { key: 'status', sortable: true },
      { key: 'actions' },
    ]

    const tableColumns = computed(() => {
      return isExpandSession.value ? [{ key: 'session', sortable: true }, ...defaultColumns] : defaultColumns;
    })

    return {
      // Sidebar

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refStudentsListTable,
      isExpandSession,

      moment,

      searchQuery,

      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,

      // Filter
      avatarText,
      title,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
