<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="courseData === undefined">
      <h4 class="alert-heading">Error fetching course data</h4>
      <div class="alert-body">
        No course found with this course id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-course-list' }"> Course List </b-link>
        for other courses.
      </div>
    </b-alert>

    <template v-if="courseData">
      <!-- First Row -->
      <b-row class="mb-2">
        <b-col cols="12" xl="6" lg="6" md="12">
          <course-view-course-info-card :course-data="courseData" />
        </b-col>
        <b-col cols="12" md="12" xl="3" lg="3" class="d-flex" >
          <course-view-course-plan-card
            v-on:refresh="refetchCourse"
            v-if="courseData.instructor"
            :user-id="courseData.instructor.id"
          />
          <b-card v-else no-body class="mb-0 w-100">
            <b-card-header>
              <h5 class="mb-0">No Instructor Assigned</h5>
            </b-card-header>
            <b-card-body class="d-flex flex-column justify-content-between">
              <vue-autosuggest
                ref="autocomplete"
                v-model="instructorQuery"
                :suggestions="instructorSuggestions"
                :input-props="instructorInputProps"
                :section-configs="instructorSectionConfigs"
                :render-suggestion="instructorRenderSuggestion"
                :get-suggestion-value="instructorGetSuggestionValue"
                @input="instructorFetchResults"
              />

              <b-button @click="doSelectInstructor" variant="primary" class="mt-1" block>Assign Instructor</b-button>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" md="12" xl="3" lg="3">
          <course-view-course-timeline-card v-if="courseData && courseData.id" :course-data="courseData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="12" md="12">
          <course-view-course-permissions-card
            @refreshStudents="refetchStudents"
            v-if="courseData"
            :course-data.sync="courseData"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" lg="12">
          <class-list v-if="courseData && courseData.id" :course-id="courseData.id"></class-list>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink, BButton, BCard, BCardHeader, BCardBody, BCardText } from 'bootstrap-vue';
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue';
import rosterStoreModule from '../rosterStoreModule';
import userStoreModule from '../../user/userStoreModule';
import CourseViewCourseInfoCard from './CourseViewCourseInfoCard';
import CourseViewCoursePlanCard from './CourseViewUserPlanCard.vue';
import CourseViewCourseTimelineCard from './CourseViewUserTimelineCard.vue';
import CourseViewCoursePermissionsCard from './CourseViewUserPermissionsCard.vue';
import ClassList from '@/views/apps/roster/classes-list/ClassList';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  components: {
    ClassList,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    VueAutosuggest,

    // Local Components
    CourseViewCourseInfoCard,
    CourseViewCoursePlanCard,
    CourseViewCourseTimelineCard,
    CourseViewCoursePermissionsCard,

    InvoiceList,
  },
  methods: {
    async doSelectInstructor() {
      if (!this.instructorSelected) {
        return;
      }

      this.$store
        .dispatch('app-roster/updateCourse', {
          courseId: this.$route.params.id,
          courseData: {
            instructor: this.instructorSelected.id,
          },
        })
        .then((response) => {
          console.log(`updated course`, response);

          if (response.data) {
            this.courseData.instructor = { id: response.data.instructor };
          }
        })
        .catch((e) => {
          console.error(`failed to update course`, e);
        });
    },
    refetchStudents() {
      console.log(`fetching course and applying students`);
      store
        .dispatch('app-roster/fetchCourseSummary', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          this.courseData = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            this.courseData.value.profiles = [];
          }
        });
    },

    instructorFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('app-user/fetchUsers', {
          search: this.instructorQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.instructorSuggestions = [];

          const users = values[0].data.results;

          users.length && this.instructorSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    instructorRenderSuggestion(suggestion) {
      if (suggestion.name === 'hotels') {
        const image = suggestion.item;
        return (
          <div class="d-flex">
            <div>
              <b-avatar src={image.thumbnailUrl} class="mr-50"></b-avatar>
            </div>
            <div>
              <span>{image.title}</span>
            </div>
          </div>
        );
      }
      return suggestion.item.name;
    },
    instructorGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return name === 'hotels' ? item.title : item.name;
    },
  },
  mounted() {
    // this.refetchStudents();
    this.refetchCourse();
  },
  setup() {
    const courseData = ref({ students: [], classes: [] });

    const COURSE_APP_STORE_MODULE_NAME = 'app-roster';
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME))
      store.registerModule(COURSE_APP_STORE_MODULE_NAME, rosterStoreModule);

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const refetchCourse = () => {
      store
        .dispatch('app-roster/fetchCourseSummary', { id: router.currentRoute.params.id })
        .then((response) => {
          courseData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
    };

    /*
       v-model="instructorQuery"
                  :suggestions="instructorSuggestions"
                  :input-props="instructorInputProps"
                  :section-configs="instructorSectionConfigs"
                  :render-suggestion="instructorRenderSuggestion"
                  :get-suggestion-value="instructorGetSuggestionValue"
                  @input="instructorFetchResults"
     */

    const instructorQuery = ref('');
    const instructorSuggestions = ref([]);
    const instructorSelected = ref(undefined);
    const instructorSectionConfigs = {
      users: {
        limit: 10,
        label: 'Users',
        onSelected: (selected) => {
          instructorSelected.value = selected.item;
        },
      },
    };

    const instructorInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for users',
      class: 'form-control',
      name: 'ajax',
    };

    return {
      courseData,
      refetchCourse,

      instructorQuery,
      instructorSuggestions,
      instructorSectionConfigs,
      instructorInputProps,
      instructorSelected,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
