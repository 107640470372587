<template>
  <b-card class="mb-0 h-100">
    <b-row>
      <!-- Course Info: Left col -->
      <b-col cols="12" xl="7" class="d-flex justify-content-center flex-column">
        <!-- Course Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            :src="courseData.avatar"
            :text="avatarText(courseData.name)"
            :variant="`light-${resolveCourseStatusVariant(courseData.status)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ courseData.name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap w-50">
              <b-button :to="{ name: 'apps-roster-course-edit', params: { id: courseData.id } }" variant="primary" block>
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="5" class="d-flex align-items-center">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ courseData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Visibility</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ courseData.visibility }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Start Date</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ moment(courseData.startDate).utc().format('l') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="AwardIcon" class="mr-75" />
              <span class="font-weight-bold">End Date</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ moment(courseData.endDate).utc().format('l') }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BMedia, BLink } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useCoursesList from '../courses-list/useCoursesList';
import moment from 'moment';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BLink,
  },
  props: {
    courseData: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    const title = this.courseData.name ? (`${this.courseData.name} - View`) : 'View Course';
    return {
      title,
    };
  },
  setup() {
    const { resolveCourseStatusVariant, resolveUserRoleVariant } = useCoursesList();

    return {
      avatarText,
      resolveCourseStatusVariant,
      resolveUserRoleVariant,
      moment,
    };
  },
};
</script>

<style></style>
