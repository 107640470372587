import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import moment from 'moment';
import store from '@/store';

export default function useSessionSessionsList(sessionId) {
  // Use toast
  const toast = useToast();

  const refStudentsListTable = ref(null);
  const isCurrent = ref(true);

  const tableColumns = [
    // { key: 'multiSelect', label: '', stickyColumn: true, variant: 'primary', sortable: false },
    { key: 'name', sortable: true },
    { key: 'uploadedResume', label: 'Resume', sortable: true },
    { key: 'uploadedCoe', label: 'COE', sortable: true },
    { key: 'uploadedTranscript', label: 'Transcript', sortable: true },
    { key: 'submittedToVa', label: 'Submission', sortable: true },
    { key: 'fundingSource', label: 'Funding', sortable: true },
    { key: 'toc', label: 'ToC', sortable: true },
    { key: 'sessionDate', label: 'Enrolled', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];

  const perPage = ref(5);
  const totalProfiles = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [3, 5, 10, 15, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('sessionDate');
  const isSortDirDesc = ref(true);

  const idsOnPage = ref([]);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refStudentsListTable.value ? refStudentsListTable.value.localItems.length : 0;

    console.log(perPage.value, currentPage.value, localItemsCount, totalProfiles.value);

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProfiles.value,
    };
  });

  const refetchData = () => {
    if (refStudentsListTable.value) {
      refStudentsListTable.value.refresh();
    }
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter, isCurrent], (a) => {
    console.log(`'upd`, a);
    refetchData();
  });

  const fetchProfiles = (ctx, callback) => {
    console.log(`fetching profiles for session`, sessionId);
    const pw = sessionId;

    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'student,reassignFrom',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    if (!isCurrent.value) {
      pl.reassignFrom = sessionId;
    }

    store
      .dispatch('app-roster/fetchSessionStudents', { sessionId, queryParams: pl })
      .then((response) => {
        console.log(`loaded students`, response.data);
        const { results, totalResults } = response.data;
        idsOnPage.value = results.map(result => result.id || result._id)

        callback(results);
        totalProfiles.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching student list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProfileStatusVariant = (status) => {
    if (status === 'created') return 'secondary';
    if (status === 'pending') return 'danger';
    if (status === 'action_required') return 'primary';
    if (status === 'enrolled') return 'info';
    if (status === 'withdrew') return 'warning';
    if (status === 'terminated') return 'danger';
    if (status === 'graduated') return 'success';
    return 'primary';
  };

  const resolveProfilePaymentStatusVariant = (status) => {
    if (status === 'unpaid') return 'danger';
    if (status === 'partially_paid') return 'warning';
    if (status === 'payment_plan') return 'info';
    if (status === 'paid') return 'success';
    return 'primary';
  };

  // enum: ['open', 'vettec', 'vrrap', 'wioa'],

  const resolveProfileProgramVariant = (status) => {
    if (status === 'open') return 'success';
    if (status === 'vettec') return 'info';
    if (status === 'vrrap') return 'primary';
    if (status === 'wioa') return 'warning';
    return 'secondary';
  };

  const resolveProfileYNVariant = (status) => {
    if (status === 'yes' || status === true) return 'success';
    if (status === 'no' || status === false) return 'danger';
    return 'warning';
  };

  return {
    fetchProfiles,
    tableColumns,
    perPage,
    currentPage,
    totalProfiles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStudentsListTable,
    idsOnPage,
    isCurrent,

    refetchData,

    //
    resolveProfileStatusVariant,
    resolveProfileProgramVariant,
    resolveProfilePaymentStatusVariant,
    resolveProfileYNVariant,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
