<template>
  <b-card title="Course Controls" class="mb-0 h-100">
    <div class="d-flex flex-wrap">
      <b-button variant="outline-danger" block @click="takeClassAttendance"> Take Attendance </b-button>
      <b-button variant="outline-info" block v-b-modal.modal-send-broadcast> Send Email Broadcast </b-button>
    </div>

    <b-modal
      id="modal-send-broadcast"
      ref="modal-send-broadcast"
      cancel-variant="outline-secondary"
      ok-title="Send Broadcast"
      cancel-title="Close"
      centered
      title="Send email to all students in course"
      @ok="sendEmailBroadcast"
      size="lg"
    >
      <b-form>
        <b-form-group>
          <label for="access-code">Access Code</label>
          <b-form-input id="access-code" v-model="accessCode" placeholder="123456"></b-form-input>
        </b-form-group>

        <hr />

        <b-form-group>
          <label for="subject">Subject</label>
          <b-form-input id="subject" v-model="broadcastSubject" placeholder="Your broadcast subject"></b-form-input>
        </b-form-group>
        <b-form-group>
          <label for="message">Body</label>
          <!--          <b-form-textarea id="message" v-model="broadcastEmail" style="min-height:480px;"></b-form-textarea>-->

          <quill-editor id="message" v-model="broadcastEmail" :options="{}" />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BMedia,
  BAvatar,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BCardText,
  BFormInput,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { quillEditor } from 'vue-quill-editor';

// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';

export default {
  components: {
    quillEditor,
    BButton,
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BCardText,
    BFormInput,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    courseData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'));
    },
  },
  data: () => {
    return {
      accessCode: undefined,
      broadcastSubject: undefined,
      broadcastEmail: undefined,
    };
  },
  mounted() {
    this.broadcastEmail = `<p>Hello class,</p><br><p>{{ messageContent }}</p><br><p>Thank you,<br>${this.userData.name}</p>`;
  },
  watch: {
    broadcastEmail() {
      console.log(this.broadcastEmail);
    },
  },
  methods: {
    takeClassAttendance() {
      console.log(`Mark class attendance`);
    },
    downloadRosterSheet() {
      console.log(`download roster sheet`);
    },
    sendEmailBroadcast(event) {
      event.preventDefault();

      console.log(event, this.broadcastSubject, this.broadcastEmail);

      if (!this.broadcastEmail || !this.broadcastSubject) {
        return;
      }

      this.$swal({
        title: 'Are you sure?',
        text: 'Your broadcast will be sent immediately after accepting. This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('app-roster/sendCourseBroadcast', {
              courseId: this.courseData.id,
              broadcast: {
                accessCode: this.accessCode,
                subject: this.broadcastSubject,
                body: this.broadcastEmail,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                this.$refs['modal-send-broadcast'].hide();

                this.$swal({
                  icon: 'success',
                  title: 'Sent!',
                  text: 'Your broadcast has been queued to be sent.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              }
            })
            .catch((error) => {
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to send broadcast',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response ? error.response.data.message : error.message,
                },
              });
            });
        }
      });
    },
  },
  setup(props) {
    const SUBJECT_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(SUBJECT_APP_STORE_MODULE_NAME))
      store.registerModule(SUBJECT_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUBJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(SUBJECT_APP_STORE_MODULE_NAME);
    });
  },
};
</script>

<style></style>
